/**************************************************
 * Created by zhaohuabing on 2019/3/19 下午2:32.
 **************************************************/
import key from './key'

import { Resource, Fetch } from '@ekuaibao/fetch'
const verifyCodes = new Resource('/api/innerapi/v1/verifyCodes')
const center = new Resource('/api/extension/center')
const renewalServiceUrl = new Resource('/api/v1/crm')
const crmActions = new Resource('/api/v1/third')
const hasPermission = new Resource('/api/pay/v2/chanpay/extension/permission')
const adminForMC = new Resource('/api/v1/group/client/author/info')
const getCorporationGrant = new Resource('/api/pay/v2/api/corporationGrant')
const netAutoCheckRule = new Resource('/api/pay/v1/plugin/processor')

export function getChargeCardList(params) {
  // const join = { join: 'power.icon,icon,/v1/attachment/attachments' }
  const { status, powerName } = params
  return center.POST('/getList', { powerName }, { status})
}

export function getChargeList(params) {
  // const join = { join: 'power.icon,icon,/v1/attachment/attachments' }
  const { status, powerName } = params
  return {
    type: key[`GET_CHARGE_LIST_${status}`],
    payload: center.POST('/getList', { powerName }, { status})
  }
}
export function getChargeByCode(powerCode) {
  return {
    type: key[`GET_CHARGE_BY_POWERCODE`],
    payload: center.GET('/v2/getPower/$powerCode', { powerCode })
  }
}

export function getVerifyCodes() {
  return {
    type: key.GET_VERIFY_CODES,
    payload: verifyCodes.POST()
  }
}
// 购买
export function renewalService(params) {
  return {
    type: key.POST_RENEWAL_SERVICE,
    payload: renewalServiceUrl.POST('', { ...params })
  }
}
// 开通
export function applyOpen(powerCode) {
  return {
    type: key.GET_APPLY_OPEN,
    payload: center.GET('/openPower/$powerCode', { powerCode })
  }
}

export function getCRMBindInfo(powerCode) {
  return {
    type: key.GET_CRM_BIND_INFO,
    payload: crmActions.GET('/checkIsBind/$powerCode', { powerCode })
  }
}

export function bindCRMAccount(param) {
  const { powerCode, data } = param
  return {
    type: key.BIND_CRM_ACCOUNT,
    payload: crmActions.PUT('/bindAccount/$powerCode', { ...data, powerCode })
  }
}

export function unbindCRMAccount(powerCode) {
  return {
    type: key.UNBIND_CRM_ACCOUNT,
    payload: crmActions.PUT('/unbindAccount/$powerCode', { powerCode })
  }
}

export function getHistoryDetail(powerCode, done) {
  return {
    type: key.GET_HISTORY_DETAIL,
    payload: center.GET('/getHistory/$powerCode', { powerCode }),
    done
  }
}

export function checkNeedCellPhone(type) {
  return {
    type: key.GET_NEED_OPEN_CELLPHONE_MODAL,
    payload: center.GET('/getPower/$type/needPhone', { type })
  }
}

export function checkHasPermission() {
  return hasPermission.GET()
}

export function postAdminForMC(params) {
  return adminForMC.POST('/save',params)
}

export function getAdminForMC() {
  return adminForMC.GET('/list',)
}
//获取企业配置详情
//https://yapi.ekuaibao.com/project/62/interface/api/38299
export function getCorporationGrantInfo(channel='') {
  return getCorporationGrant.GET('/$channel', { channel })
}

export function queryCorporationGrantInfo(channel='', params) {
  return getCorporationGrant.POST('/query/$channel', { channel, ...params })
}

export function putCorporationGrantInfo(channel='', params, type) {
  return getCorporationGrant.POST('/$channel', { channel, ...params }, type)
}

export function checkCorporationGrantInfo(channel='', params) {
  return getCorporationGrant.POST('/check/$channel', { channel, ...params })
}

export function postCorporationGrantFile(params, callbackResult) {
  const xhr = new XMLHttpRequest()

  const formData = new FormData()

  formData.append('file', params.file)
  formData.append('certPassword', params.certPassword)
  const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const accessToken = encodeURIComponent(Fetch.accessToken)

  const url = `/api/pay/v2/api/corporationGrant/file/$ICBCPAY?corpId=${ekbCorpId}`

  xhr.open('post', url, true)

  xhr.onload = async function onload() {
    return callbackResult(JSON.parse(xhr.response))
    // allow success when 2xx status
    // see https://github.com/react-component/upload/issues/34
    if (xhr.status < 200 || xhr.status >= 300) {
      // return option.onError(getError(option, xhr))
    }
    if (option.method) {
      option.onSuccess(option.data)
    } else {
      const response = getBody(xhr)
      if (response) {
        option.onSuccess(response, xhr)
      } else if (option.data) {
        option.onSuccess(option.data)
      } else {
        option.onSuccess()
      }
    }
  }
  xhr.send(formData)
}

// 获取、保存CBS支付检查任务配置
export function CBSNetAutoCheckRuleAction(params) {
  return netAutoCheckRule.POST('/$channel', params, { channel: 'CBSPAY' })
}