import { app as api } from '@ekuaibao/whispered'
import * as actions from './expansion-center-action'
import { showMessage } from '@ekuaibao/show-util'
import { get } from 'lodash'
import moment from 'moment'

export function getVerifyCode(item) {
  api.dispatch(actions.getVerifyCodes()).then(res => {
    const verifyCode = res.value.verifyCode
    api.open('@expansion-center:GetVerifyCodeModal', { ...item, verifyCode })
  })
}

export function fnRenewOrOpen(params) {
  let { userInfo, powerCode, power, type, title, isOpen } = params
  const { name, authorizeType } = power
  let staffName = get(userInfo, 'staff.name')
  let corpName = get(userInfo, 'staff.corporation.name')
  title = i18n.get(`「{__k0}」{__k1}{__k2}`, {
    __k0: name,
    __k1: i18n.get('功能'),
    __k2: isOpen ? i18n.get('开通') : i18n.get('续费')
  })
  let data = { staffName, corpName, title, authorizeType, isOpen }
  let text = isOpen ? i18n.get('开通') : i18n.get('续费')
  let entryPoint = `${name}${text}`
  if(window.__PLANTFORM__ === "NBBANK"){
    return api.open('@layout:CluesInfoModal')
  }
  return api.open('@expansion-center:SubscribeServiceModal', { ...data }).then(result => {
    
    let data = { ...result, entryPoint }
    if (isOpen && authorizeType === 'FREE') {
      return fnOpenFreeFetch(powerCode)
    }
    return fnChargeFetch(data)
  })
}

function fnOpenFreeFetch(powerCode) {
  return api.dispatch(actions.applyOpen(powerCode)).then(() => {
    showMessage.info(i18n.get('开通成功'))
  })
}

function fnChargeFetch(data) {
  return api
    .invokeService('@expansion-center:Renewal:service', data)
    .then(result => {
      showMessage.info(i18n.get('发送成功'))
      return result
    })
    .catch(() => {
      showMessage.info(i18n.get('发送失败'))
    })
}

export function remainExpireTimeView(props) {
  const {
    power: { authorizeType },
    status,
    expireTime
  } = props
  const grayColor = 'card-introduce'
  const redColor = 'card-introduce color-red'
  const orangeColor = 'card-introduce color-orange'
  if (status === 'OPENED' && (authorizeType === 'FREE' || authorizeType === 'ONETIME')) {
    return {
      date: i18n.get('永久有效'),
      classNames: grayColor
    }
  } else if (status === 'EXPIRE') {
    return {
      date: i18n.get('已到期'),
      classNames: orangeColor
    }
  } else {
    const expireDate = moment(expireTime).format('YYYY-MM-DD 00:00:00')
    const today = moment().format('YYYY-MM-DD 00:00:00')
    const remainDate = moment(expireDate).diff(moment(today), 'days', false)
    if (remainDate > 0 && remainDate <= 7) {
      return {
        date: `${remainDate} ${i18n.get('天后到期')}`,
        classNames: redColor
      }
    } else if (remainDate === 0) {
      return {
        date: i18n.get('今天到期'),
        classNames: redColor
      }
    }
    return {
      date: `${remainDate} ${i18n.get('天后到期')}`,
      classNames: grayColor
    }
  }
}

export function fnCheckNeedPhone({ groupType, type }) {
  return new Promise((resolve, reject) => {
    if (groupType === 'CRM') {
      api.dispatch(actions.checkNeedCellPhone(type)).then(result => {
        resolve(result.value)
      })
    } else {
      resolve(false)
    }
  })
}

export const array2Obj = (arr=[], key='') => {
  //只匹配第一个key
  let _obj = {};
  arr.forEach(item => {
    if(item[key] && !_obj[item[key]]) _obj[item[key]] = item
  })
  return _obj
}

export const getHasExCenterPermission = (userInfo) => {
  const SYS_ADMIN = 'SYS_ADMIN'
  const { permissions = [], hasExCenterPermission } = userInfo
  let isAdministrator = permissions.includes(SYS_ADMIN)
  if (window.IS_SZJL) {
    if (hasExCenterPermission && !permissions.includes(SYS_ADMIN)) {
      userInfo.permissions.push(SYS_ADMIN)
    }
    isAdministrator = hasExCenterPermission
  }
  return {
    isAdministrator,
    userInfo,
  }
}