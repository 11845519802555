// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import {
  renewalService,
  checkHasPermission,
  getCorporationGrantInfo,
} from './expansion-center-action'
import { fnCheckNeedPhone } from './action-util'
import loadable from '@loadable/component'

export default [
  {
    id: '@expansion-center',
    reducer: () => import('./expansion-center-reducer'),
    path: '/expansion-center/:id',
    ref: '/',
    onload: () => import('./expansion-center-view'),
    'Renewal:service': (params) => api.dispatch(renewalService(params)),
    'import:fnCheckNeedPhone': () => fnCheckNeedPhone,
    'check:has:permission': () => checkHasPermission,
    'get:corporation:grant:info': (params) => getCorporationGrantInfo(params),
    dependencies: ['@supplier-setting'],
  },
  {
    id: '@expansion-center',
    path: '/expansion-center',
    ref: '/',
    exact: true,
    onload: () => import('./expansion-center-view'),
  },
  {
    id: '@expansion-center',
    path: '/expansion-center-old',
    ref: '/',
    exact: true,
    onload: () => import('./expansion-center-view')
  },
  {
    point: '@@layers',
    prefix: '@expansion-center',
    onload: () => import('./layers'),
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'expansion-center',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 1.1,
        label: '扩展中心',
        href: '/expansion-center',
        icon: 'menu-loan',
      },
    ],
  },
  {
    resource: '@expansion-center',
    value: {
      ['crm/bind-crm-account-modal']: loadable(() =>
        import('./elements/crm/bind-crm-account-modal'),
      ),
      ['elements/ChargeCardDetail']: loadable(() => import('./elements/ChargeCardDetail')),
    },
  },
]
