/**************************************************
 * Created by zhaohuabing on 2019/3/19 下午2:29.
 **************************************************/

export const ID = '@expansion-center'

export default {
  ID: ID,
  GET_CHARGE_LIST_OPENED: `${ID}/GET_CHARGE_LIST_OPENED`,
  GET_CHARGE_LIST_UNOPENED: `${ID}/GET_CHARGE_LIST_UNOPENED`,
  GET_CHARGE_BY_POWERCODE: `${ID}/GET_CHARGE_BY_POWERCODE`,
  GET_CARD_DETAIL: `${ID}/GET_CARD_DETAIL`,
  GET_CARD_RECORD: `${ID}/GET_CARD_RECORD`,
  GET_VERIFY_CODES: `${ID}/GET_VERIFY_CODES`,
  POST_RENEWAL_SERVICE: `${ID}/POST_RENEWAL_SERVICE`,
  GET_APPLY_OPEN: `${ID}/GET_APPLY_OPEN`,
  GET_CRM_BIND_INFO: `${ID}/GET_CRM_BIND_INFO`,
  BIND_CRM_ACCOUNT: `${ID}/BIND_CRM_ACCOUNT`,
  UNBIND_CRM_ACCOUNT: `${ID}/UNBIND_CRM_ACCOUNT`,
  GET_HISTORY_DETAIL: `${ID}/GET_HISTORY_DETAIL`,
  GET_NEED_OPEN_CELLPHONE_MODAL: `${ID}/GET_NEED_OPEN_CELLPHONE_MODAL`
}
